import { i18n } from '../i18n';

export class Translations {
  private _t: Function;

  constructor() {
    this.editorInit = this.editorInit.bind(this);
  }

  editorInit(locale, baseUrl) {
    return i18n({ locale, baseUrl }).then(({ t }) => {
      this._t = t;
    });
  }

  t(key, options = {}) {
    if (!this._t) {
      throw new Error('Translation service was not initialized yet!');
    }
    return this._t(key, options);
  }
}
