const panelPrefix = 'https://editor.wix.com/admin-pages-platform-app';

export const panelDefinitions = {
  addPagePanel: sdkScriptSrc => ({
    width: 744,
    height: 533,
    shouldHideHeader: true,
    url: `${panelPrefix}/add-page-panel.html?wix-sdk-version=${sdkScriptSrc}`,
  }),
  emptyStatePanel: sdkScriptSrc => ({
    title: 'Empty State',
    width: 666,
    height: 508,
    url: `${panelPrefix}/empty-state-panel.html?wix-sdk-version=${sdkScriptSrc}`,
  }),
  pageInfoPanel: sdkScriptSrc => ({
    title: 'Page Settings',
    url: `${panelPrefix}/page-info-panel.html?wix-sdk-version=${sdkScriptSrc}`,
  }),
  permissionsPanel: sdkScriptSrc => ({
    title: 'Page Permissions',
    url: `${panelPrefix}/permissions-panel.html?wix-sdk-version=${sdkScriptSrc}`,
  }),
};
